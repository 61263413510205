export const ProposalEnquiryConstant = {
    Header: {
        SEARCH_CRITERIA: ' Search Criteria',
        COMPANY_CODE: 'Company Code',
        PROPOSAL_NO: 'Proposal No',
        OPERATING_TML: 'Operating Tml',
        EFFECTIVE_DATE: 'Effective Date',
        EFFECTIVE_DATE_RANGE: "Effective Date Range",
        SEQ: 'Seq',
        TAR_TYPE: 'Tariff Type',
        TAR_CODE: 'Tariff Code',
        FORWARDER: 'Forwarder',
        CURRENCY: 'Currency',
        PROPOSAL_TYPE: 'Proposal Type',
        ACTIVE_IND: 'Active Ind',
        STATUS: 'Status',
        REMARKS: 'Remarks',
        CONFIRMED_DATE: 'Confirmed Date',
        CONFIRM_STATUS: 'Confirm Status'
    },

    Detail: {
        CYCLE: 'CYCLE',
        FOR_EVERY: 'FOR EVERY',
        UOM: 'UOM',
        RATE: 'RATE',
        MINIMUM: 'MINIMUM',
        SURCHARGE: 'SURCHARGE',
        SURCHARGE_TYPE: 'SURCHARGE TYPE',
        ADDITIONAL_UOM: 'ADDITIONAL UOM',
        ADDITIONAL_RATE: 'ADDITIONAL_RATE',
    },

}