
export interface ProposalEnquirySearchCriteria {
	companyCode?: string[] | null,
	proposalNo?: string | null,
	operatingTml?: string[] | null,
	effectiveFromDate?: Date | null,
	effectiveToDate?: Date | null,
	tariffType?: string[] | null,
	tariffCode?: string[] | null,
	proposalStatus?: string[] | null,
	activeInd?: string | null,
	confirmStatusList?: string[] | null,

	[key: string]: string[] | string | Date | null | undefined | Object
}

export const EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA: ProposalEnquirySearchCriteria = {

	companyCode: [],
	proposalNo: null,
	operatingTml: [],
	effectiveFromDate: null,
	effectiveToDate: null,
	tariffType: [],
	tariffCode: [],
	proposalStatus: [],
	activeInd: null,
	confirmStatusList: [],
}