import { ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
// import { StandardProposalEnabledSearchCriteria, DEFAULT_ENABLED_STANDARD_PROPOSAL_SEARCH_CRITERIA } from "presentation/constant/StandardProposal/StandardProposalEnabledSearchCriteria";
import { EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, ProposalEnquirySearchCriteria } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
// import { GeneralProposalSearchCriteria, EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DEFAULT_ENABLED_PROPOSAL_ENQUIRY_CRITERIA, EnabledProposalEnquiryCriteria } from "domain/entity/ProposalEnquiry/EnabledProposalEnquiryCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";

export interface ProposalEnquiryDropdownOptions {
    //To add: Effective Date Range, Proposal No
    companyCodeDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    proposalStatusDropdownOptions: DropdownProps[],
    activeIndDropdownOption: DropdownProps[],
}

// export interface GroupCheckboxs {
//     billingCycleCheckBoxOptions: GroupCheckboxList[],
// }



export interface ProposalEnquiryModel {
    isLoading: boolean,
    isShowRightCriteriaPanel: boolean,
    isShowProposalDetail: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: EnabledProposalEnquiryCriteria,
    // checkboxValue: StandardProposalEnabledSearchCriteria,
    searchCriteria: ProposalEnquirySearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: ProposalEnquiryDropdownOptions,
    documentActive: boolean,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    userEmail: string,
    searchPreferences: SearchPreferenceEntity<ProposalEnquirySearchCriteria, EnabledProposalEnquiryCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<ProposalEnquirySearchCriteria, EnabledProposalEnquiryCriteria> | null,
    // openedContainerNo?: string,
    // isShowContainerDetail: boolean;
    proposals: ProposalEnquiryEntity[],
    currentProposal: ProposalEnquiryEntity | null,
    isShowLegendButton: boolean,
    selectedRows: ProposalEnquiryEntity[],
    // isShowPrintPad: boolean,
    // docPrintCriteria: DocumentEnquiryPrintCriteria,
    // dynamicGroupCheckboxs: GroupCheckboxs,
    // currentHdr: DocumentHeaderEntity | null,
    title: string,
    isList: boolean,
    isDirectory: boolean,
    isEditCriteria: boolean,
    isEdit: boolean,
    isFullScreen: boolean,
    enableActivate: boolean,
    isDoing: boolean,
}

export const EMPTY_PROPOSAL_ENQUIRY_MODEL: ProposalEnquiryModel = {
    isLoading: true,
    isShowRightCriteriaPanel: true,
    isShowProposalDetail: false,
    isFilterSearchCriteria: true,
    isEditSearchCriteria: true,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_PROPOSAL_ENQUIRY_CRITERIA },
    // checkboxValue: StandardProposalEnabledSearchCriteria,
    searchCriteria: { ...EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA },
    highlightedCheckboxKey: null,
    searchCounter: 0,
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        proposalStatusDropdownOptions: [],
        activeIndDropdownOption: [],
    },
    documentActive: false,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    userEmail: "",
    searchPreferences: [],
    currentSearchPreference: null,
    // openedContainerNo?: string,
    // isShowContainerDetail: boolean;
    proposals: [],
    currentProposal: null,
    isShowLegendButton: false,
    selectedRows: [],
    // isShowPrintPad: boolean,
    // docPrintCriteria: DocumentEnquiryPrintCriteria,
    // dynamicGroupCheckboxs: GroupCheckboxs,
    // currentHdr: DocumentHeaderEntity | null,
    title: "Proposal List",
    isList: true,
    isDirectory: false,
    isEditCriteria: false,
    isEdit: false,
    isFullScreen: false,
    enableActivate: false,
    isDoing: false,
}
